import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import Skeleton from '@shared/ui/progress-loaders/Skeleton';
import { ContainerColumn } from '@components/styled';
import { BodyMediumSemiBold, BodySemiBold, BodySmall } from '@components/styled/Typography';
const RecieveAmountContainer = styled(ContainerColumn)(props => ({
    gap: props.theme.spacing_sizes.xs * 1.25,
}));
const RecieveAmountText = styled(BodyMediumSemiBold)(props => ({
    backgroundColor: '#F8FAFF',
    borderRadius: 6,
    padding: props.theme.spacing_sizes.xm,
    lineHeight: '24px',
}));
const RecieveAmountLabel = styled(BodySemiBold)();
const ErrorText = styled(BodySmall)(props => ({
    color: props.theme.palette.error.main,
}));
const RecieveAmountBadge = ({ isLoading = undefined, amount = 0, error = undefined, }) => {
    const { t } = useTranslation();
    return (_jsxs(_Fragment, { children: [_jsxs(RecieveAmountContainer, { children: [_jsx(RecieveAmountLabel, { children: t('overview.balance_management.enter_amount.you_recieve') }), isLoading ? (_jsx(Skeleton, { animationTime: '1750ms', animation: 'wave', height: 60 })) : (_jsx(RecieveAmountText, { children: `${amount} USDT` }))] }), (!isLoading && error) && _jsx(ErrorText, { children: error })] }));
};
export default RecieveAmountBadge;
